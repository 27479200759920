<!--
 * @Author: 伽蓝
 * @Date: 2022-11-07 23:21:54
 * @LastEditTime: 2022-11-21 22:46:42
 * @LastEditors: 伽蓝
 * @FilePath: /pc-h5/src/components/work/mod/MarketingMix.vue
 * @Description:
 * 代码不规范,调试泪两行
-->
<template>
  <div class="text-collection" :style="style">
    <div class="box-row" :style="spacingR" v-for="(list, ri) in menuList" :key="ri + Math.random().toString(8)">
      <div class="box-cell" :style="spacingC" v-for="(info, ci) in list" :key="ci + Math.random().toString(8)">
        <TextItem
          v-if="info"
          :data="data"
          :info="info"
          :colIndex="ci"
          :maxPriceCount="maxPriceCountByCol[ci]"></TextItem>
      </div>
    </div>
  </div>
</template>

<script>
import { compareVersion } from '../../../tools/utils';
import TextItem from "./TextItem.vue";
export default {
  components: { TextItem },
  props: {
    data: {
      type: Object,
      default() {
        return {
          images: [],
          text: []
        }
      },
      required: true
    },
    info: {
      type: Object,
      required: true
    }
  },

  computed: {
    isVersion3() {
      return compareVersion(this.data.version, '3.0.0') >= 0
    },
    priceHolder() {
      return this.data.menuIconList.find(item => item.id === 'priceHolder' && item.url && item.applyRange.text)
    },
    style() {
      let { textRow, textCol, textWidth, textHeight, textSpacingC, textSpacingR } = this.data;
      let width = textCol * textWidth + (textCol - 1) * textSpacingC + 4;
      let height = textRow * textHeight + (textRow - 1) * textSpacingR + 4;
      return {
        width: width + "px",
        height: height + "px",
      };
    },
    spacingC() {
      let { textSpacingC } = this.data;
      return {
        marginRight: textSpacingC + "px",
      };
    },
    spacingR() {
      let { textSpacingR } = this.data;
      return {
        marginBottom: textSpacingR + "px",
      };
    },
    menuList() {
      const { textRow, textCol } = this.data
      return Array.from({ length: +textRow }).map((_, ri) => {
        return Array.from({ length: +textCol }).map((_, ci) => {
          return this.formatItem(this.getInfo(ri, ci))
        })
      })
    },
    maxPriceCountByCol() {
      if (!this.isVersion3) return []
      return Array.from({ length: this.data.textCol }).map((_, index) => {
        return Math.max(...this.menuList.map(row => row[index] ? row[index].showPriceList.length : 0))
      })
    }
  },
  mounted() {
    // console.log('textCollection===========>', this.info);
  },
  methods: {
    formatItem(info) {
      if (!info) return
      return {
        ...info,
        showPriceList: this.getShowPriceList(info)
      }
    },
    getInfo(row, col) {
      try {
        let { textCol } = this.data;
        let index = row * textCol + col;
        return this.info.text[index]
      } catch (error) {
        return null
      }
    },
    getMatchedPriceList(info) {
      const { matchedSkuList } = info
      if (!matchedSkuList) return []
      const { textPriceSpecs, textPriceSort } = this.data
      // 不限展示规格时价格排序字段生效
      if (textPriceSpecs.length === 0) {
        return [...matchedSkuList]
          .sort((a, b) => textPriceSort === 'DESC' ? b.salePrice - a.salePrice : a.salePrice - b.salePrice)
      }
      // 限定展示规格时按限定规格顺序展示
      return textPriceSpecs.map(spec => {
        // 根据规格匹配到多个sku时, 展示价格最小的sku
        const skus = matchedSkuList
          .filter(item => item.specName.split(',').includes(spec))
          .sort((a, b) => a.salePrice - b.salePrice)
        return skus[0] || { salePrice: '', discountPrice: '' }
      })
    },
    getShowPriceList(info) {
      const { textPriceCols, textPriceCompact } = this.data
      const matchedPriceList = this.getMatchedPriceList(info)
      let priceList = []
      if (textPriceCompact !== 'default') {
        // 按价格去重并过滤无值的
        const priceSet = new Set()
        priceList = matchedPriceList.reduce((acc, cur) => {
          if (cur.salePrice !== '' && !priceSet.has(cur.salePrice)) {
            acc.push({ price: cur.salePrice, discountPrice: cur.discountPrice })
            priceSet.add(cur.salePrice)
          }
          return acc
        }, [])
      } else {
        priceList = matchedPriceList.map(item => ({ price: item.salePrice, discountPrice: item.discountPrice }))
      }
      priceList = priceList.slice(0, textPriceCols)
      if (textPriceCols > priceList.length && this.priceHolder) {
        const holderList = Array.from({ length: textPriceCols - priceList.length }).map(() => ({ price: '', discountPrice: '' }))
        priceList = textPriceCompact === 'right' ? [...holderList, ...priceList] : [...priceList, ...holderList]
      }
      return priceList
    }
  }
};
</script>

<style scoped lang="scss">

.text-collection{
  box-sizing: border-box;
  .box-row{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:last-child{
      margin-bottom: 0 !important;
    }
  }
  .box-cell{
    &:last-child{
      margin-right: 0 !important;
    }
  }
}
</style>
